<template>
  <div class="admin-users">
    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">
        <a-button
          type="default"
          style="display: inline; margin-right: 15px"
          @click.prevent="$router.push('/admin')"
          icon="left"
        ></a-button>

        <admin-org-indicator></admin-org-indicator>

        Admin - Settings
      </h1>
    </div>
    <!-- / Page Header -->

    <!-- Menu -->
    <div class="menu">
      <a-row type="flex" :gutter="20">
        <a-col :span="colSpan" v-for="(item, i) in menuItems" :key="i">
          <admin-menu-item
            :img="item.img"
            :title="item.title"
            @click.native="() => handleItemClicked(item)"
          ></admin-menu-item>
        </a-col>
      </a-row>
    </div>
    <!-- / Menu -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AdminMenuItem from "../../components/Admin/AdminMenuItem.vue";
export default {
  components: { AdminMenuItem },
  data() {
    return {
      windowWidth: window.innerWidth,

      menuItems: [
        {
          title: "Emergency Types",
          onClick: () => {
            this.$router.push("/admin/settings/emergency-types");
          },
          img: "/admin/emergency.jpg",
        },
        {
          title: "Incident Types",
          onClick: () => {
            this.$router.push("/admin/settings/incident-types");
          },
          img: "/admin/incident-types.jpg"
        },
        {
          title: "Incident Type Groups",
          onClick: () => {
            this.$router.push("/admin/settings/incident-type-groups");
          },
          img: "/admin/incident-type-group.jpg"
        },
        {
          title: "Windows Installs",
          onClick: () => {
            this.$router.push("/admin/settings/windows-installs");
          },
          img: "/admin/windows-installs.jpg",
        },
        {
          title: "Quick Messages",
          onClick: () => {
            this.$router.push("/admin/settings/quick-messages");
          },
          img: "/admin/quick-messages.jpg",
        },
        {
          title: "Announcement Templates",
          onClick: () => {
            this.$router.push("/admin/settings/announcement-templates");
          },
          img: "/admin/announcement-templates.jpg",
        },
        // {
        //   title: "Playbooks",
        //   onClick: () => {
        //     this.$router.push("/admin/settings/playbooks");
        //   },
        //   img: "/admin/playbook.jpg",
        // },
        {
          title: "Locations",
          onClick: () => {
            this.$router.push("/admin/settings/locations");
          },
          img: "/admin/locations.jpg",
        },
        {
          title: "Organisation Settings",
          onClick: () => {
            this.$router.push("/admin/organisational-settings");
          },
          img: "/admin/organisation_settings.jpg",
        },
      ],
    };
  },

  methods: {
    handleItemClicked(item) {
      if (item.onClick) {
        item.onClick();
      }
    },
  },

  mounted() {
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth;
    });
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
  },

  computed: {
    ...mapGetters("admin", {
      selectedOrganisation: "selectedOrganisation",
    }),

    colSpan() {
      if (this.windowWidth > 1200) {
        return 8;
      }
      if (this.windowWidth > 800) {
        return 12;
      }
      if (this.windowWidth > 500) {
        return 24;
      }
      return 24;
    },
  },
};
</script>

<style scoped lang="scss">
.menu {
  margin-top: 20px;
}

.admin-menu-item {
  margin-bottom: 15px;
}
</style>